import {
  mdiCurrencyUsd,
} from "@mdi/js";

export default {
  methods: {
    resolveUserTotalIcon(total) {
      if (total === "Total Users")
        return { icon: mdiCurrencyUsd, color: "primary" };
      if (total === "Paid Users")
        return { icon: mdiCurrencyUsd, color: "error" };
      if (total === "Active Users")
        return { icon: mdiCurrencyUsd, color: "success" };
      if (total === "Pending Users")
        return { icon: mdiCurrencyUsd, color: "warning" };
        
      return { icon: mdiCurrencyUsd, color: "primary" };
    },

    resolveOrderBilledVariant(status) {
      if (status === 1) return "success";
      if (status === 0) return "secondary";

      return "primary";
    },

    resolveStatusVariant(status) {
      if (status === "order") return "success";
      if (status === "cancelled") return "error";
      if (status === "draft") return "secondary";
      if (status === "pendding") return "warning";
    },
  }
}
