<template>
  <v-dialog v-model="dialog" max-width="850px" origin="center center" :fullscreen="isMobile">
    <v-card>
      <v-card-title>Detalhes da Venda #{{
        orderContent.number ? orderContent.number : "..."
      }}
      </v-card-title>
      <v-divider></v-divider>
      <div v-if="loading" class="d-flex justify-center">
        <v-progress-circular indeterminate color="secondary" class="ma-6"></v-progress-circular>
      </div>
      <v-card-text v-show="!loading">
        <div class="pa-4 c">
          <div class="row py-2">
            <v-icon class="mx-2">
              {{ icons.mdiAccount }}
            </v-icon>
            <h2>{{ orderCustomer.name }}</h2>
          </div>
          <div class="row py-2">
            <v-icon class="mx-2">
              {{ icons.mdiMapMarker }}
            </v-icon>
            <span>{{ orderCustomer.main_address.neighborhood }}</span>
          </div>
          <div class="row py-2">
            <v-icon class="mx-2">
              {{ icons.mdiPhone }}
            </v-icon>
            <span>{{ orderCustomer.phone }}</span>
          </div>
        </div>
        <v-data-table :headers="tableColumns" :items="[orderContent]">
          <template v-slot:footer>
            <!-- Empty footer slot to hide the paginator and per page selector -->
          </template>

        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" class="text-capitalize" @click="openCancelOrderAlert" :disabled="orderContent.billed == 1">
          Cancelar Venda
        </v-btn>
        <v-btn color="accent" class="text-capitalize" @click="billOrder()" :disabled="orderContent.billed == 1">
          Emitir NF
        </v-btn>
        <v-btn color="secondary" class="text-capitalize" @click="close()">
          Voltar
        </v-btn>
      </v-card-actions>
    </v-card>

    <CancelOrder ref="cancelOrder" />
  </v-dialog>
</template>

<script>
import { mdiAccount, mdiMapMarker, mdiPhone } from "@mdi/js";
import CancelOrder from "@/components/orders/form/CancelOrder.vue";

export default {
  name: "OrderDetail",

  components: {
    CancelOrder
  },

  data() {
    return {
      dialog: false,
      loading: false,
      order_id: null,
      page: null,
      pageCount: null,

      icons: {
        mdiAccount,
        mdiMapMarker,
        mdiPhone,
      },

      orderContent: {
        name: null,
        number: null,
        phone: null,
      },
      orderCustomer: {
        order_product: null,
        billed: null,
        main_address: {
          neighborhood: null,
        },
      },

      tableColumns: [
        { text: "Cod", value: "serviceCode" },
        { text: "Descrição", value: "description" },
        { text: "Valor", value: "value" },
        // { text: "Quantidade", value: "quantity" },
        // { text: "Total", value: "value" },
      ],
    };
  },

  methods: {
    open(id) {
      this.dialog = true;
      this.loading = true;
      this.order_id = id;
      this.getOrder(id);
    },

    close() {
      this.dialog = false;
    },

    getOrder(id) {
      this.$http.$get(`/order/${id}`).then((res) => {
        this.orderContent = res.data;
        this.orderContent.value = this.$options.filters.moneyBr(this.orderContent.value / 100)
        this.$http.$get(`/customer/${res.data.customer_id}`).then((res) => {
          this.orderCustomer = res.data;
          this.loading = false;
        });
        this.getServiceCode(this.orderContent.service_id)
      });
    },
    getServiceCode(id) {
      this.$http.$get(`/service/${id}`).then((res) => {
        this.orderContent = {
          ...this.orderContent,
          serviceCode: res.data.code
        }
      })
    },

    cancelOrder() {
      this.http.destroy(`/order/${this.order_id}`).then((response) => {
        this.dialog = false;
        this.$emit("onSubmit");
      });

    },

    openCancelOrderAlert() {
      this.dialog = false;
      this.$refs.cancelOrder.open(this.order_id);
    },

    billOrder() {
      this.loading = true;
      this.$http
        .store("nota-fiscal-avulsa", {
          order_id: this.order_id,
        })
        .then((result) => {
          this.loading = false;
          this.$emit("onSubmit");
          this.dialog = false;
        })
        .catch((error) => {
          this.loading = false;

        });
    },
  },
};
</script>
