<template>
    <v-text-field
        @input="handleInput"
        v-money="money"
        :label="label"
        :rules="rules"
        prefix="R$"
        dense filled outlined
        hide-details="auto"
        ref="input"
    ></v-text-field>
</template>

<script>
import { VMoney } from 'v-money'


export default {
    props: {
        label: {
            type: String,
            default: 'Valor'
        },
        hidden:{
            default: 0
        },
        rules: {
            type: Array
        }
    },
    directives: {
        money: VMoney,
    },
    model: {
        prop: 'hidden',
        event: 'input'
    },
    data(){
        return {
            money: {
                decimal: ',',
                thousands: '.',
                precision: 2,
                masked: false,
            }
        }
    },
    methods: {
        handleInput(value){
            if (value != null) {
                return this.$emit('input', parseInt(value.replaceAll(',', '').replaceAll('.', '')))
            }
        },
        reset(value = null){
            this.$refs.input.$el.querySelector("input").value = value ? value : '0,00'
            this.$emit('input', value ? value : 0)
        }

    }
}
</script>
<style>
    .v-text-field__prefix{
        margin-top: 0px !important;
    }
</style>