<template>
  <v-dialog v-model="dialog" max-width="500px" origin="center center">
    <v-card>
      <v-card-title>
        <span> Filtrar Vendas </span>
        <v-progress-circular
          indeterminate
          v-if="loading"
          class="ma-2"
          color="secondary"
        ></v-progress-circular>
      </v-card-title>
      <v-card-text>
        <div class="row">
          <div class="col-12">
            <v-text-field
              v-model="filter.order_customer"
              label="Cliente"
              item-text="name"
              dense
              filled
              outlined
              hide-details="auto"
            ></v-text-field>
          </div>
          <div class="col-12">
            <v-text-field
              v-model="filter.product"
              label="Produto"
              item-text="name"
              dense
              filled
              outlined
              hide-details="auto"
            ></v-text-field>
          </div>
          <div class="col-12">
            <v-select
              v-model="filter.platform"
              label="Plataforma"
              :items="platform"
              dense
              filled
              outlined
              hide-details="auto"
            ></v-select>
          </div>
        </div>
        <div class="row col-xs px-3 py-2">
          <date-picker
            v-model="date"
            label="Data do Pedido"
            :range="true"
          ></date-picker>
        </div>
        <div class="row col-xs">
          <div class="col-md">
            <money-input
              v-model="filter.valueMin"
              label="Valor Mínimo"
            ></money-input>
          </div>
          <div class="col-md">
            <money-input
              v-model="filter.valueMax"
              label="Valor Máximo"
            ></money-input>
          </div>
        </div>
        <div class="row col-xs">
          <div class="col-md">
            <v-select
              :items="selectBilled"
              v-model="filter.billed"
              item-text="name"
              item-value="value"
              label="Faturado"
              dense
              outlined
            ></v-select>
          </div>
          <div class="col-md">
            <v-select
              :items="selectedStatus"
              v-model="filter.status"
              item-text="name"
              item-value="value"
              label="Status"
              dense
              outlined
            ></v-select>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="submitFilter()"> Aplicar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Filter from "@/util/Filters";
import moneyInput from "@/components/app/ui/moneyInput.vue";
import datePicker from "@/components/app/ui/datePicker.vue";
import formMixin from "@/util/mixins/formMixin";

export default {
  components: {
    moneyInput,
    datePicker,
  },

  mixins: [formMixin],

  data() {
    return {
      dialog: false,
      loading: false,

      date: null,

      filter: {
        order_customer: null,
        product: null,
        platform: null,
        ordered_at_min: null,
        ordered_at_max: null,
        valueMin: null,
        valueMax: null,
        billed: null,
        status: null,
        is_filter: true,
      },

      rules: {
        greaterThanMin: (v) =>
          this.filter.valueMin <= this.filter.valueMax ||
          "O valor máximo deve ser maior que o valor minimo.",
      },

      selectBilled: [
        { name: "Faturado", value: 1 },
        { name: "Não Faturado", value: 0 },
      ],

      selectedStatus: [
        { name: "Rascunho", value: "draft" },
        { name: "Pendente", value: "pendding" },
        { name: "Processado", value: "order" },
        { name: "Cancelado", value: "cancelled" },
      ],

      platform: ["Braip", "Monetizze", "Eduzz", "Hotmart"],

      processedData: null,

      touched: false,
    };
  },

  watch: {
    filter: {
      deep: true,
      handler() {
        this.touched = true;
      },
    },
  },

  methods: {
    open() {
      this.filter = {
        order_customer: null,
        product: null,
        platform: null,
        ordered_at_min: null,
        ordered_at_max: null,
        valueMin: null,
        valueMax: null,
        billed: null,
        status: null,
        is_filter: true,
      };
      this.touched = false;
      this.dialog = true;
    },

    dateCheck(from, to, check) {
      return (
        Date.parse(check) <= Date.parse(to) &&
        Date.parse(check) >= Date.parse(from)
      );
    },

    handlingDate(date) {
      if (date == null) {
        this.filter.ordered_at_min = null;
        this.filter.ordered_at_max = null;
      } else {
        this.filter.ordered_at_min = date[0];
        this.filter.ordered_at_max = date[1];
      }
    },

    submitFilter() {
      if (this.touched) {
        if (this.filter.valueMin == 0) {
          this.filter.valueMin = null;
        }

        if (this.filter.valueMax == 0) {
          this.filter.valueMax = null;
        }

        this.handlingDate(this.date);

        this.$emit("filter", this.filter);
      }

      this.dialog = false;
      this.loading = false;
    },
  },
};
</script>

<style>
.v-text-field__prefix {
  margin-top: 0px !important;
}
</style>
