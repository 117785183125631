import { mdiCurrencyUsd } from "@mdi/js";

export default {

  methods: {
    resolveUserStatusVariant(status) {
      if (status === 1) return "success";
      if (status === 0) return "secondary";

      return "primary";
    },
  
    avatarText(value) {
      if (!value) return ''
      const nameArray = value.split(' ')

      return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
    },
  
  }

}