<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Faturar Notas
        </v-card-title>

        <v-card-text>
          Você está prestes a faturar as notas selecionadas, deseja continuar?
        </v-card-text>

        <v-card-text>
          <date-picker
            ref="emitedPickerRef"
            v-model="emited_at"
            label="Data de Emissão"
            :range="false"
          ></date-picker>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            color="secondary"
            text
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="text-capitalize"
            color="success"
            text
            @click="bill()"
          >
            Faturar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import datePicker from "@/components/app/ui/datePicker.vue";
import { format } from "date-fns";

export default {
  components: {
    datePicker,
  },

  data() {
    return {
      dialog: false,
      emited_at: format(new Date(), "yyyy-MM-dd"),
      ids: null,
    };
  },

  methods: {
    open(ids) {
      this.ids = ids;
      this.dialog = true;
    },

    bill() {
      this.$http
        .$post("/nota-fiscal-faturar", {
          orders: this.ids,
          billed_at: this.emited_at,
        })
        .then((response) => {
          this.getOrders();
          this.dialog = false;
        })
        .catch((error) => {
          console.log(error);
        });
        this.dialog = false
    },
  },
};
</script>

<style>
</style>