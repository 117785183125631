<template>
    <div class="d-flex flex-column justify-space-between align-center pa-8">
        <v-img
            src="@/assets/images/svg/empty.svg"
            max-height="300px"
            max-width="300px"
            class="align-self ma-8"
        />
        <h2>
            {{ text }}
        </h2>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: 'Nada por aqui... por enquanto.'
        }
    }
}
</script>