<template>
  <v-dialog v-model="dialog" persistent width="500">
    <v-card>
      <v-card-title class="headline"> Atenção </v-card-title>
      <v-card-text> Você realmente deseja excluir esta venda? </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" outlined @click="close"> Cancelar </v-btn>

        <template>
          <v-btn color="error" @click="deleteMethod()"> Excluir </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
  <script>
export default {
  data() {
    return {
      dialog: false,
      order_id: null,
    };
  },

  methods: {
    open(id) {
      this.dialog = true;
      this.order_id = id;
    },

    close() {
      this.dialog = false;
    },

    deleteMethod() {
      this.$http.destroy(`/order/${this.order_id}`).then((response) => {
        this.dialog = false;
        window.location.reload();
      });

      this.close();
    },
  },
};
</script>